import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../Buttons";

export const GoBack = React.memo(({ to, onBack }) => {
  const navigate = useNavigate();
  return (
    <Button
      startIcon={
        <ArrowBackIosRoundedIcon
          color="neutrals.main"
          sx={{ mr: 0.2, fontSize: "4rem", mb: "0.08rem" }}
        />
      }
      onClick={() => (onBack ? onBack() : navigate(-1))}
      sx={{
        color: "neutrals.light",
        fontFamily: "Montserrat",
        fontWeight: "600",

        px: 0,
        ":hover": { bgcolor: "transparent" },
      }}
    >
      {to || "Back"}
    </Button>
  );
});
