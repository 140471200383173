import {
  FormControl,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import Radio from "./Radio";

const RadioGroup = ({
  label,
  name,
  value,
  onChange,
  options = [],
  error = false,
  errorMsg = "",
}) => {
  return (
    <FormControl>
      <FormLabel
        sx={{
          color: "secondary.main",
          fontFamily: "Montserrat",
          fontWeight: "600",
        }}
        id={label}
      >
        {label}
      </FormLabel>

      <MuiRadioGroup
        aria-labelledby={label}
        {...{ name, value, onChange }}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "3rem",
        }}
      >
        {options.map((option) => (
          <Radio key={option.value} label={option.label} value={option.value} />
        ))}
      </MuiRadioGroup>
      {error && (
        <Typography
          sx={{
            color: "error.main",
            fontSize: "1rem",
            mt: "0.5rem",
          }}
          variant="subtitle2"
        >
          {errorMsg}{" "}
        </Typography>
      )}
    </FormControl>
  );
};

export default React.memo(RadioGroup);
