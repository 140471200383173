import { Typography, TextField as MuiTextField, InputLabel } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";

const Input = styled(InputBase)(({ theme }) => ({
  border: "1.5px solid " + theme.palette.neutrals["300"],
  borderRadius: "2rem",
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    borderRadius: "2rem",
    backgroundColor: `${theme.palette.common.white} !important`,
    fontSize: "1rem",
    padding: "1rem",
    color: theme.palette.neutrals["900"],
    fontWeight: "600",
    fontFamily: "Montserrat",
    transition: theme.transitions.create(["border-color"]),

    "&:-internal-autofill-selected": {
      backgroundColor: `${theme.palette.common.white} !important`,
    },
    "&.Mui-disabled": {
      color: theme.palette.secondary.mediumLight,
      WebkitTextFillColor: theme.palette.secondary.mediumLight,
    },
  },
  "&.Mui-focused": {
    borderColor: theme.palette.primary.mediumLight,
  },

  "&.Mui-error": {
    borderColor: theme.palette.error.main,
  },
}));

const TextField = ({
  label,
  value,
  error = false,
  InputProps,
  errorMsg = "",
  ...rest
}) => {
  return (
    <FormControl variant="standard" fullWidth error={error}>
      {!!label && (
        <InputLabel
          shrink
          sx={{
            color: "primary.main",
            fontSize: "1.3rem",
            fontWeight: "600",
            "&.Mui-error": {
              fontWeight: 600,
            },
          }}
        >
          {label}
        </InputLabel>
      )}
      <Input
        value={value}
        {...rest}
        error={error}
        endAdornment={InputProps || <></>}
      />
      {error && (
        <Typography
          sx={{
            color: "error.main",
            fontSize: "1rem",
            mt: "0.5rem",
          }}
          variant="subtitle2"
        >
          {errorMsg}{" "}
        </Typography>
      )}
    </FormControl>
  );
};
export default TextField;
