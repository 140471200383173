import styled from "@emotion/styled";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import {
  FormControl,
  InputBase,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  Typography,
} from "@mui/material";
import React from "react";
const Input = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiSvgIcon-root": {
    color: theme.palette.neutrals["main"],
    right: 5,
    fontSize: 18,
  },
  "& .MuiInputBase-input": {
    borderRadius: "0.5rem",
    position: "relative",
    backgroundColor: theme.palette.common.white,
    fontSize: "1rem",
    padding: "1rem",
    border: "1.5px solid " + theme.palette.neutrals["300"],
    transition: theme.transitions.create(["border-color"]),
    color: theme.palette.neutrals["900"],
    fontWeight: "600",
    fontFamily: "Montserrat",
    "&:focus": {
      borderRadius: "0.5rem",
      backgroundColor: theme.palette.common.white,
      border: "1.5px solid " + theme.palette.neutrals["300"],
    },
    "&.Mui-disabled": {
      color: theme.palette.primary.main,
      WebkitTextFillColor: theme.palette.primary.main,
    },
  },
  "&.Mui-error .MuiInputBase-input": {
    borderColor: theme.palette.error.main,
  },
}));
const Select = ({
  label,
  placeholder,
  value = "none",
  onChange,
  options = [],
  error = false,
  errorMsg = "",
  ...rest
}) => {
  // console.log(error, "svsjkdvskd");
  return (
    <>
      <FormControl variant="standard" error={error} fullWidth>
        {!!label && (
          <InputLabel
            shrink
            sx={{
              color: "primary.main",
              fontSize: "1.3rem",
              fontWeight: "600",
              "&.Mui-error": {
                fontWeight: 600,
              },
            }}
          >
            {label}
          </InputLabel>
        )}
        <MuiSelect
          value={value}
          onChange={(e) => {
            onChange(e);
            e.stopPropagation();
          }}
          IconComponent={ArrowDropDownCircleOutlinedIcon}
          input={<Input error={error} />}
          sx={{
            maxHeight: 300,
            ".MuiSelect-icon": {
              fontSize: "1.3rem !important",
              right: "1rem !important",
              color: "neutrals.light",
            },
          }}
          {...rest}
        >
          <MenuItem value={"none"} disabled={true}>
            {placeholder}
          </MenuItem>
          {options.map((option, ind) => (
            <MenuItem
              key={option?.value + ind}
              value={option?.value}
              disabled={option?.disabled}
              sx={{
                fontWeight: "600",
                fontFamily: "Montserrat",
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </MuiSelect>
        {error && (
          <Typography
            sx={{
              color: "error.main",
              fontSize: "1rem",
              mt: "0.5rem",
            }}
            variant="subtitle2"
          >
            {errorMsg}{" "}
          </Typography>
        )}
      </FormControl>
    </>
  );
};

export default Select;
