import React from "react";
import { Button as MuiButton } from "@mui/material";
import { Add, Edit, Remove } from "@mui/icons-material";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export function ToggleButtons({
  handleChange,
  value = "",
  btnList = [],
  sx = {},
}) {
  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={handleChange}
      fullWidth
      sx={{
        p: 1,
        border: "1px solid",
        borderColor: "neutrals.300",
      }}
    >
      {btnList.map((btn) => (
        <ToggleButton
          key={btn.type}
          value={btn.type}
          aria-label={btn?.text}
          sx={{
            fontSize: "1rem",
            color: "primary.main",
            border: "none",
            borderRadius: "3px !important",
            ...sx,
          }}
        >
          {btn.icon || btn?.text}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

export const Button = ({ children, variant, ...rest }) => {
  return (
    <MuiButton variant={variant} {...rest}>
      {children}
    </MuiButton>
  );
};

export const PrimaryButton = ({ children, sx = {}, ...rest }) => {
  return (
    <MuiButton
      variant={"contained"}
      sx={{
        bgcolor: "secondary.main",
        ...sx,
      }}
      {...rest}
    >
      {children}
    </MuiButton>
  );
};

export const SecondaryButton = ({ children, size="medium",fontSize = "inherit", sx = {}, ...rest }) => {
  return (
    <MuiButton
      variant={""}
      size={size} 
      sx={{
        bgcolor: "secondary.mediumLight",
        color: "secondary.main",
        ":hover": {},
        fontSize: fontSize,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </MuiButton>
  );
};

export const DefaultButton = ({ children, size="medium",fontSize = "inherit", sx = {}, ...rest }) => {
  return (
    <MuiButton
      variant={""}
      size={size} 
      sx={{
        bgcolor: "secondary.light",
        color: "neutrals.dark",
        ":hover": {},
        fontSize: fontSize,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </MuiButton>
  );
};

export const TertiaryButton = ({ children, sx = {}, ...rest }) => {
  return (
    <MuiButton
      variant={""}
      sx={{
        color: "primary.mediumLight",
        textDecoration: "underline",
        textUnderlineOffset: 5,
        ":hover": {
          color: "primary.mediumLight",
          textDecoration: "underline",
          bgcolor: "#fff",
        },

        ...sx,
      }}
      {...rest}
    >
      {children}
    </MuiButton>
  );
};
