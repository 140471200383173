import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useAutocomplete,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "50%",
  border: `1px solid ${theme.palette.primary.light}`,
  backgroundColor: "#666F80",
  marginRight: 0,
  marginLeft: 0,
  width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: "-16px 0px !important",
  height: "100%",
  width: "7rem",
  position: "absolute",
  right: 0,
  // pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "2rem",
  color: theme.palette.neutrals.main,
}));

const Input = styled("input")(({ theme }) => ({
  color: "#373737",
  fontWeight: 600,
  border: "1px solid " + theme?.palette?.neutrals["300"],
  outline: "1px solid " + "#666F80",
  // padding: theme.spacing(2, 1, 2, 0),

  [theme.breakpoints.up("xs")]: {
    padding: "8px 4px 8px 0",
    paddingLeft: `16px`,
  },
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(2, 1, 2, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  },
  borderRadius: "5rem",
  transition: theme.transitions.create("width"),
  width: "100%",
  "&:focus": {
    borderColor: theme.palette.custom.dark,
  },
}));

export const SearchBox = React.memo(
  ({
    list = [],
    basesOn = "name",
    navigateTo = "",
    onClick = () => {},
    ...rest
  }) => {
    const {
      getRootProps,
      getInputLabelProps,
      getInputProps,
      getListboxProps,
      getOptionProps,
      groupedOptions,
    } = useAutocomplete({
      id: "use-autocomplete-demo",
      options: list,
      getOptionLabel: (option) => option[basesOn],
    });
    const navigation = useNavigate();

    return (
      <Box position={"relative"} width={"100%"}>
        <Search {...getRootProps()}>
          <SearchIconWrapper {...getInputLabelProps()}>
            <SearchIcon />
          </SearchIconWrapper>
          <Input
            sx={{ fontSize: "1rem" }}
            fullWidth
            {...getInputProps()}
            {...rest}
          />
        </Search>

        {groupedOptions && groupedOptions?.length > 0 && (
          <List
            disablePadding
            {...getListboxProps()}
            sx={{
              position: "absolute",
              top: "105%",
              width: "100%",
              maxHeight: 300,
              border: "1px solid",
              borderColor: "neutrals.300",
              bgcolor: "common.white",
              overflow: "auto",
              zIndex: 1,
            }}
          >
            {groupedOptions.map((option, index) => {
              // console.log(option);
              return (
                <ListItem
                  key={option?.id || option?.userId || index}
                  disablePadding
                  {...getOptionProps({ option, index })}
                >
                  <ListItemButton
                    onClick={() => {
                      !navigateTo
                        ? onClick(option)
                        : navigation(navigateTo + "/" + option?.id);
                    }}
                    sx={{
                      fontSize: 20,
                    }}
                  >
                    <ListItemText
                      primary={
                        option?.id
                          ? option[basesOn]
                          : `${option["User.firstName"]} ${option["User.lastName"]}`
                      }
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        )}
      </Box>
    );
  }
);

export const PopSearchBox = React.memo((props) => {
  return (
    <Box position={"relative"} width={"100%"}>
      <Search>
        <Input sx={{ fontSize: "1rem" }} fullWidth {...props} />
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
      </Search>
    </Box>
  );
});

export const SearchBoxWithApi = React.memo(
  ({ dispatchAction = () => {}, ...rest }) => {
    const [query, setQuery] = useState("");
    const [clear, setClear] = useState(false);

    // const debouncedSearch = _.debounce((value) => {
    //   dispatchAction({ name: value });
    // }, 1000);

    // useEffect(() => {
    //   debouncedSearch(query);
    //   return () => {
    //     debouncedSearch.cancel();
    //   };
    // }, [query]);

    const handleQueryChange = (event) => {
      setQuery(event.target.value);
    };

    return (
      <Box position={"relative"} width={"100%"}>
        <Search>
          <SearchIconWrapper>
            <Button
              sx={{
                height: "100%",
                width: "100%",
                zIndex: "99999",
                position: "relative",
              }}
              variant="contained"
              onClick={() => {
                console.log("scijsf");
                if (query?.length) {
                  dispatchAction(query);
                  setClear(!clear);
                  if (clear) {
                    setQuery("");
                    dispatchAction("");
                  }
                }
              }}
            >
              {clear ? "Clear" : "Search"}
            </Button>
          </SearchIconWrapper>
          <Input
            sx={{ fontSize: "1rem" }}
            fullWidth
            value={query}
            onChange={handleQueryChange}
            {...rest}
          />
        </Search>
      </Box>
    );
  }
);
