import { RadioButtonUncheckedRounded } from "@mui/icons-material";
import { FormControlLabel, Radio as MuiRadio } from "@mui/material";
import React from "react";

const Radio = ({
  value,
  label,
  icon = (
    <RadioButtonUncheckedRounded
      sx={{
        color: "secondary.mediumLight",
      }}
    />
  ),
  checkedIcon,
  ...rest
}) => {
  return (
    <>
      <FormControlLabel
        value={value}
        control={
          <MuiRadio
            icon={icon}
            checkedIcon={checkedIcon}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 28,
              },
            }}
          />
        }
        label={label}
        {...rest}
      />
    </>
  );
};

export default React.memo(Radio);
