import { Typography } from "@mui/material";
import React from "react";

export const Heading = React.memo(({ children, ...rest }) => {
  return (
    <Typography
      {...rest}
      variant="h1"
      fontSize={{ sm: "1rem", md: "1.7rem" }}
      textTransform={"capitalize"}
    >
      {children}
    </Typography>
  );
});
