import dayjs from "dayjs";

import _ from "lodash";

export const API_ERROR = "Something went wrong!";

export const _differenceBwObj = (obj1, obj2) => {
  return _.pickBy(obj1, (value, key) => !_.isEqual(obj2[key], value));
};

export const fileToFormData = (file) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("fileName", file.name);
  return formData;
};

export const removeCheckboxStyle = {
  mr: 0,
  fontSize: "1.2rem",
  ".MuiFormControlLabel-root": {
    m: 0,
  },
  "&.Mui-checked .MuiSvgIcon-root": {
    bgcolor: "secondary.light",
    borderRadius: 0.5,
    color: "common.white",
  },
};

export const queryPath = ({ page, pageSize = 9, sortBy, state, name }) => {
  var query = "?";
  if (page && pageSize) {
    query = query + `${page ? `page=${page}&pageSize=${pageSize}&` : ""}`;
  }

  if (sortBy) {
    query += `${sortBy ? `sortBy=${sortBy}&` : ""}`;
  }

  if (state) {
    query = query + `${state ? `state=${state}&` : ""}`;
  }
  if (name) {
    query = query + `${name ? `name=${name}` : ""}`;
  }

  return query;
};

export const sortText = (text) => {
  return text?.length > 40 ? `${text?.substring(0, 20)}...` : text;
};

export const isFile = (value) => {
  return value[0] instanceof File;
};

export function maskSensitiveInfo(input) {
  if (input.includes("@")) {
    // Masking email
    let [username, domain] = input.split("@");
    let maskedUsername =
      username.length > 2
        ? username[0] +
          "*".repeat(username.length - 2) +
          username[username.length - 1]
        : username;
    let maskedDomain =
      domain.length > 2
        ? domain[0] + "*".repeat(domain.length - 2) + domain[domain.length - 1]
        : domain;
    return maskedUsername + "@" + maskedDomain;
  } else {
    // Masking phone number
    let maskedNumber =
      input.length > 4
        ? input[0] + "*".repeat(input.length - 4) + input.slice(-3)
        : input;
    return maskedNumber;
  }
}

export function checkFileType(value, dummy) {
  if (typeof value === "string") {
    const newValue = value?.split("?")[0];
    // Check if the value is a URL
    if (newValue.match(/\.(jpeg|jpg|png|gif|pdf|svg)$/i)) {
      if (newValue.match(/\.(jpeg|jpg|png|gif|svg)$/i)) {
        return <img src={value} alt="" />;
      } else if (newValue.match(/\.pdf$/i)) {
        return (
          <iframe
            style={{
              width: "100%",
            }}
            src={value}
            frameBorder="0"
          ></iframe>
        );
      }
    } else {
      console.log(value, "weqfnjoqwf");

      return <img src={dummy} alt="" />;
    }
  } else if (value && value[0] instanceof File) {
    // Check if the value is a File object
    const allowedExtensions = ["jpeg", "jpg", "png", "gif", "pdf", "svg"];
    const fileExtension = value[0].name.split(".").pop().toLowerCase();
    console.log(fileExtension, "sdvknjno");
    if (allowedExtensions.includes(fileExtension)) {
      if (fileExtension.match(/(jpeg|jpg|png|gif|svg)$/i)) {
        return <img src={window.URL.createObjectURL(value[0])} alt="" />;
      } else if (fileExtension === "pdf") {
        return (
          <iframe
            style={{
              width: "100%",
            }}
            src={window.URL.createObjectURL(value[0])}
            frameBorder="0"
          ></iframe>
        );
      }
    } else {
      return <img src={dummy} alt="" />;
    }
  } else {
    return <img src={dummy} alt="" />;
  }
}

export const GOOGLE_MAPS_API_KEY = "AIzaSyA0sKhPX_7pw8IG4ZO_1P61agCVrP-BCHg";

export async function getCoordinates(address) {
  return fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address="${
      address?.house_number?.startsWith("#")
        ? address?.house_number?.replace("#", "")
        : address?.house_number
    }+${address?.landmark || ""}, +${address?.street_1}+${
      address?.street_2 || ""
    }, +${address?.city}, +${address?.state}+${
      address?.pincode
    }"&key=${GOOGLE_MAPS_API_KEY}`
  )
    .then((response) => response.json())
    .then((data) => {
      console.log(data, "fslknfls");
      const latitude = data.results[0]?.geometry?.location?.lat;
      const longitude = data.results[0]?.geometry?.location?.lng;
      return { latitude, longitude };
    });
}

export function isTwoHoursBeforeDelivery(deliveryTime) {
  // Get the current time
  const currentTime = dayjs();

  // Get the delivery time as a Day.js object
  const deliveryTimeObj = dayjs(deliveryTime);
  // Calculate the duration between the current time and the delivery time
  const timeDifference = deliveryTimeObj.diff(currentTime, "hour");

  console.log(
    {
      curr: currentTime.format("YYYY-MM-DDTHH:mm:ss"),
      sched: deliveryTimeObj.format("YYYY-MM-DDTHH:mm:ss"),
      diff: timeDifference,
    },
    "vihdsuhvo"
  );

  // Check if the time difference is less than or equal to 2 hours
  return timeDifference;
}
