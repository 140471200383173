import styled from "@emotion/styled";
import { KeyboardArrowDownRounded } from "@mui/icons-material";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import {
  FormControl,
  InputBase,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  Typography,
} from "@mui/material";
import React from "react";
const Input = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },

  "& .MuiSvgIcon-root": {
    fontSize: "1.5rem !important",
    borderRadius: "inherit",
    background: "#009D84",
    borderRadius: "0rem 3.125rem 3.125rem 0rem",
    padding: "5px",
    color: "#fff",
    position: "relative !important",
    top: "0",
    right: "0 !important",
    width: "3.6rem",
    height: "3.6rem",
    boxShadow:
      "-4px -4px 10px 0px rgba(30, 30, 30, 0.10), 4px 4px 10px 0px rgba(30, 30, 30, 0.10)",
  },
  "& .MuiSelect-iconOpen.MuiSvgIcon-root ": {
    borderRadius: " 3.125rem 0rem 0rem 3.125rem ",
  },
  "& .MuiInputBase-input": {
    borderRadius: "2rem 0 0 2rem",
    position: "relative",
    backgroundColor: theme.palette.common.white,
    fontSize: "1rem",
    padding: "1rem",
    paddingRight: "0 !important",
    border: "1.5px solid " + theme.palette.primary.mediumLight,
    borderRight: "0 !important",
    transition: theme.transitions.create(["border-color"]),
    color: theme.palette.primary.main,
    fontWeight: "700",
    fontFamily: "Montserrat",
    boxShadow:
      "-4px -4px 10px 0px rgba(30, 30, 30, 0.10), 4px 4px 10px 0px rgba(30, 30, 30, 0.10)",
    "&:focus": {
      borderRadius: "2rem 0 0 2rem",
      backgroundColor: theme.palette.common.white,
      border: "1.5px solid " + theme.palette.primary.mediumLight,
    },
    "&.Mui-disabled": {
      color: theme.palette.primary.main,
      WebkitTextFillColor: theme.palette.primary.main,
    },
  },
  "&.Mui-error .MuiInputBase-input": {
    borderColor: theme.palette.error.main,
  },
}));
const Select = ({
  label,
  placeholder,
  value = "none",
  onChange,
  options = [],
  error = false,
  errorMsg = "",
  ...rest
}) => {
  console.log(options, "svsjkdvskd");
  return (
    <>
      <FormControl variant="standard" error={error}>
        {!!label && (
          <InputLabel
            shrink
            sx={{
              color: "primary.main",
              "&.Mui-error": {
                fontWeight: 600,
              },
            }}
          >
            {label}
          </InputLabel>
        )}
        <MuiSelect
          value={value}
          onChange={(e) => {
            onChange(e);
            e.stopPropagation();
          }}
          IconComponent={KeyboardArrowDownRounded}
          input={<Input error={error} />}
          sx={{
            maxHeight: 300,
            ".MuiSelect-icon": {
              fontSize: "1.3rem !important",
              right: "1rem !important",
              color: "neutrals.light",
            },
          }}
          {...rest}
        >
          <MenuItem value={"none"} disabled={true}>
            {placeholder}
          </MenuItem>
          {options.map((option, ind) => (
            <MenuItem
              key={option?.value + ind}
              value={option?.value}
              disabled={option?.disabled}
              sx={{
                fontWeight: "600",
                fontFamily: "Montserrat",
                textTransform: "capitalize",
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </MuiSelect>
        {error && (
          <Typography
            sx={{
              color: "error.main",
              fontSize: "1rem",
              mt: "0.5rem",
            }}
            variant="subtitle2"
          >
            {errorMsg}{" "}
          </Typography>
        )}
      </FormControl>
    </>
  );
};




export default Select;
