import { Typography } from "@mui/material";
import React from "react";

export const FormLabel = React.memo(({ error, label }) => {
  return (
    <Typography
      variant="subtitle2"
      sx={{
        fontSize: "1rem",
        lineHeight: 1,
        color: error ? "error.main" : "primary.main",
        mb: "0.8rem",
      }}
    >
      {label}
    </Typography>
  );
});
