import React from "react";
import { Avatar as MuiAvatar } from "@mui/material";

export const Avatar = ({ src, ...rest }) => {
  return <MuiAvatar src={src} {...rest} />;
};

export const AvatarWithText = ({ text, sx, ...rest }) => {
  return (
    <MuiAvatar
      sx={{
        bgcolor: "primary.light",
        color: "secondary.main",
        fontFamily: "Montserrat",
        fontWeight: "700",
        ...sx,
      }}
      {...rest}
    >
      {text?.split("")[0]}
    </MuiAvatar>
  );
};
